\:root
  // * LHP *
  --color-purple: #8049E1
  --color-red: #D03555
  --color-green: #51AC88
  --color-green-light: #61B29F
  --color-yellow: #E5C84D
  --color-yellow-light: #F4CA76
  --color-blue: #54A4D8
  --color-blue-light: #D2EDF9
  --color-blue-lightest: #DCEEFA
  --color-accent: #F47122

  // Calculator chart colors
  --color-calculator-chart-1: var(--color-blue)
  --color-calculator-chart-2: var(--color-yellow)
  --color-calculator-chart-3: var(--color-red)
  --color-calculator-chart-4: var(--color-green)

  --color-dashboard-chart-1: #B26161
  --color-dashboard-chart-2: #A761B2
  --color-dashboard-chart-3: #3674C0
  --color-dashboard-chart-4: #65B039
  --color-dashboard-chart-5: #ECCC49
  --color-dashboard-chart-6: #E9F75D

  --color-background: #f5f6f7
  --color-background-rgb: 245, 246, 247

  --color-primary: #C61B45
  --color-primary-rgb: 198, 27, 69
  --color-primary-light: #C05D60
  --color-primary-lighter: #CC817D
  --color-primary-lighter-rgb: 204, 192, 125
  --color-primary-lightest: #D9A5A0

  --color-secondary: #30A5DD
  --color-secondary-rgb: 48, 165, 221

  --color-gray: #3a455f
  --color-gray-alt: #616364
  --color-gray-light: #8A8F9E
  --color-gray-lighter: #D8DADE
  --color-gray-lighter-alt: #B4B7C1
  --color-gray-lightest: #EBECEF

  --color-success: #61B29F
  --color-success-rgb: 97, 178, 159
  --color-info: #54A4D8
  --color-warning: #F4CA76
  --color-warning-rgb: 244, 202, 118
  --color-danger: #D03555
  --color-danger-rgb: 208, 53, 85

  --font-primary: Cabin, sans-serif
  --font-secondary: 'Open Sans', sans-serif

  // * theme *
  --ion-background-color: var(--color-background)
  --ion-background-color-rgb: var(--color-background-rgb)
  --ion-font-family: var(--font-primary)
  --ion-toolbar-background: #fff

  // * primary *
  --ion-color-primary: var(--color-primary)
  --ion-color-primary-rgb: var(--color-primary-rgb)
  --ion-color-primary-contrast: #fff
  --ion-color-primary-contrast-rgb: 255, 255, 255
  --ion-color-primary-shade: var(--color-primary-light)
  --ion-color-primary-tint: var(--color-primary-light)

  // * secondary *
  --ion-color-secondary: var(--color-secondary)
  --ion-color-secondary-rgb: var(--color-secondary-rgb)
  --ion-color-secondary-contrast: #fff
  --ion-color-secondary-contrast-rgb: 255, 255, 255
  --ion-color-secondary-shade: #0bb8cc
  --ion-color-secondary-tint: #24d6ea

  // * tertiary *
  --ion-color-tertiary: var(--color-primary-lighter)
  --ion-color-tertiary-rgb: var(--color-primary-lighter-rgb)
  --ion-color-tertiary-contrast: #fff
  --ion-color-tertiary-contrast-rgb: 255, 255, 255
  --ion-color-tertiary-shade: #633ce0
  --ion-color-tertiary-tint: #7e57ff

  // * success *
  --ion-color-success: var(--color-success)
  --ion-color-success-rgb: var(--color-success-rgb)
  --ion-color-success-contrast: #fff
  --ion-color-success-contrast-rgb: 255, 255, 255
  --ion-color-success-shade: #0ec254
  --ion-color-success-tint: #28e070

  // * warning *
  --ion-color-warning: var(--color-warning)
  --ion-color-warning-rgb: var(--color-warning-rgb)
  --ion-color-warning-contrast: #fff
  --ion-color-warning-contrast-rgb: 255, 255, 255
  --ion-color-warning-shade: #e0b500
  --ion-color-warning-tint: #ffd31a

  // * danger *
  --ion-color-danger: var(--color-danger)
  --ion-color-danger-rgb: var(--color-danger-rgb)
  --ion-color-danger-contrast: #fff
  --ion-color-danger-contrast-rgb: 255, 255, 255
  --ion-color-danger-shade: #d33939
  --ion-color-danger-tint: #f25454

  // * dark *
  --ion-color-dark: #222428
  --ion-color-dark-rgb: 34, 36, 40
  --ion-color-dark-contrast: #fff
  --ion-color-dark-contrast-rgb: 255, 255, 255
  --ion-color-dark-shade: #1e2023
  --ion-color-dark-tint: #383a3e

  // * medium *
  --ion-color-medium: #989aa2
  --ion-color-medium-rgb: 152, 154, 162
  --ion-color-medium-contrast: #fff
  --ion-color-medium-contrast-rgb: 255, 255, 255
  --ion-color-medium-shade: #86888f
  --ion-color-medium-tint: #a2a4ab

  // * light *
  --ion-color-light: #f4f5f8
  --ion-color-light-rgb: 244, 245, 248
  --ion-color-light-contrast: #000
  --ion-color-light-contrast-rgb: 0, 0, 0
  --ion-color-light-shade: #d7d8da
  --ion-color-light-tint: #f5f6f9

  // * blue *
  --ion-color-blue: var(--color-blue)
  --ion-color-blue-rgb: 84,164,216
  --ion-color-blue-contrast: #fff
  --ion-color-blue-contrast-rgb: 255,255,255
  --ion-color-blue-shade: #4a90be
  --ion-color-blue-tint: #65addc

.ion-color-blue
  --ion-color-base: var(--ion-color-blue)
  --ion-color-base-rgb: var(--ion-color-blue-rgb)
  --ion-color-contrast: var(--ion-color-blue-contrast)
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb)
  --ion-color-shade: var(--ion-color-blue-shade)
  --ion-color-tint: var(--ion-color-blue-tint)
