@import './variables'

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
@import '~@ionic/react/css/normalize.css'
@import '~@ionic/react/css/structure.css'
@import '~@ionic/react/css/typography.css'

/* Ionic CSS Utilities */
@import '~@ionic/react/css/padding.css'
@import '~@ionic/react/css/flex-utils.css'
@import '~@ionic/react/css/text-alignment.css'

/* Custom Stream Chat CSS for Loan Officers */
@import './chat'
@import './intro'
@import './layout'
@import './components/_modal'

ion-tab-bar
  --border: none
  --background: #fff

ion-card
  --background: #fff
  box-shadow: 2.13px 2.13px 12.76px rgba(#000, .07)
  border-radius: 7.4px

ion-modal.loanzify-modal
  --width: 90%
  --height: 60%
  --border-radius: 20px

  &:first-of-type
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.52)


