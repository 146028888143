.str-chat-channel-list.messaging.loanzify-conversation-channel-list
  top: 72px
  left: 0
  max-width: 100%
  min-height: unset
  max-height: calc(100% - 72px)
  overflow-y: auto
  padding: 0

.str-chat-channel-list.messaging.loanzify-conversation-channel-list.str-chat-channel-list--open
    left: 0

.loanzify-conversation-channel-list.str-chat.messaging
  background-color: #F5F6F7
  margin: 0
  box-shadow: 2px 2px 12px rgba(#000000, 0.25)

  .str-chat__channel-list-messenger
    background: transparent
    padding: 0

    .str-chat__channel-list-messenger__main
      padding: 0

    .str-chat__channel-preview-messenger
      align-items: start
      background: #fff
      height: 76px
      margin: 0
      padding-left: 1rem
      padding-right: 1rem

      .str-chat__channel-preview-messenger--left
        height: 100%
        display: flex
        align-items: center
        justify-content: center

      .str-chat__channel-preview-messenger--middle
        flex: 1 1 auto
        height: 100%
        overflow: hidden
        display: flex
        flex-direction: column
        justify-content: center

      .str-chat__channel-preview-messenger--right
        flex: 0 0 60px
        display: flex
        flex-direction: column
        justify-content: center
        height: 100%
        margin-left: auto

      .str-chat__avatar-fallback
        background-color: var(--color-blue-light)
        color: #000

    .str-chat__channel-preview-messenger:nth-of-type(odd)
      background: #F5F6F7

    .str-chat__channel-preview-messenger--name, .str-chat__channel-preview-messenger--last-message
      color: #8A8F9E

    .str-chat__channel-preview-messenger--last-message
      max-width: none
      display: flex

      p
        line-height: normal
        max-width: none
        text-overflow: ellipsis

    .str-chat__channel-preview-messenger--last-message-date
      color: #8A8F9E
      margin-right: 10px
      margin-left: auto
      display: flex
      align-items: center


    .str-chat__channel-preview-messenger--unread-count
      display: flex
      margin-left: auto
      background: #F47122
      height: 21px
      width: 25px
      align-items: center
      justify-content: center
      color: #fff
      font-size: 15px
      font-weight: bold

.myHighlightClass
  position: relative
  z-index: 9999
