ion-modal.auto-height
  --height: auto !important

  &.bottom
    align-items: flex-end

  .modal-wrapper

    .ion-page
      position: relative
      contain: content
      max-height: 90vh
      display: block

      .modal-content
        overflow: auto

      .inner-content
        max-height: 80vh
        overflow: auto
        padding: 1rem



