.h-full
  height: 100vh !important
  --height: 100vh !important

.h-auto
  height: auto !important
  --height: auto !important

.w-full
  width: 100vw !important
  --width: 100vw !important

$directions: ( t: 'top', r: 'right', b: 'bottom', l: 'left')

$sizes: (0: 0, 1: '.657rem', 2: '1rem', 3: '1.25rem', 4: '1.675rem', 5: '2rem', 6: '2.25rem', 7: '2.675rem', 8: '3rem' )

@each $directionKey, $direction in $directions
  @each $sizeKey, $size in $sizes
    .border-#{$directionKey}-radius-#{$sizeKey}
      border-#{$directionKey}-radius: $size


  @each $otherDirectionKey, $otherDirection in $directions
    @if $directionKey != $otherDirectionKey and ($directionKey == top or $directionKey == bottom) and $otherDirectionKey != top and $otherDirectionKey != bottom
      .border-#{$directionKey}-#{$otherDirectionKey}-radius-#{$sizeKey}
        border-#{$directionKey}-#{$otherDirectionKey}-radius: $size

@each $sizeKey, $size in $sizes
  .border-width-#{$sizeKey}
    border-width: $size
    --border-width: $sizeKey !important

  .border-radius-#{$sizeKey}
    border-radius: $sizeKey
    --border-radius: $sizeKey !important




.border-none
  border: none !important
