body
  .introjs-tooltipReferenceLayer
    width: 97% !important

  .loanzify-intro-tooltip
    border-radius: 7px
    max-width: 300px !important

    @media (max-width: 768px)
      width: 97%
      max-width: 97%

    .introjs-tooltip-header
      padding: 0 10px

      .introjs-skipbutton
        font-weight: 100
        font-size: 11px
        padding: 1.25rem 0 .25rem 0
        text-transform: uppercase
        margin-bottom: 1.5rem
        border-bottom: 1px solid var(--color-secondary)


    .introjs-bullets
      ul
        li
          a.active
            background: var(--color-secondary)

    .introjs-tooltiptext
      padding-top: 0

    .introjs-tooltiptext
      font-family: 'Open Sans'

    .introjs-tooltipbuttons
      text-align: left
      display: flex
      justify-content: space-between

      a.introjs-button
        border-radius: 30px
        padding: .475rem 3rem
        background: transparent
        color: var(--color-secondary)
        border-color: var(--color-secondary)
        font-family: Cabin
        font-size: 16px
        font-weight: 400
        text-shadow: none
        float: none
        letter-spacing: 1.2px


        &.introjs-prevbutton
          border: none
          color: var(--color-gray-light)


        &.introjs-nextbutton
          background-color: var(--color-secondary)
          color: #fffae3
          margin-left: auto

  .loanzify-intro-helper
    border: 1px solid var(--color-primary)
    box-shadow: rgba(33,33,33,.5) 0px 0px 0px 5000px !important
    border-radius: 7px

  .step-highlight
    transition: background-color .25s ease
    height: 100%
    width: 100%
    background-color: rgba(blue, .3%)

